html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 20px;
}

#content li {
    display: flex !important;
}

#content li::before {
    content: "\2022";
}

.cdk-global-scrollblock {
    overflow-y: visible !important;
}